import { api } from "./Api";

export const Fetch = {
  postResponse: (data) => {
    return api.postMethod('Contacted/shareToBuyers', data);
  },
  getDashboardData: (data) => {
    return api.getMethod('Properties/dashboard_count' + data, data);
  },
  getResponse: (data) => {
    return api.getMethod('users/property_share' + data, data);
  },
  getProperties: (data) => {
    return api.getMethod("Properties/show?is_admin=true&" + data, data);
  },
  exportProperties: (data) => {
    return api.getMethod("Properties/export_properties?" + data, data);
  },
  getRoles: (data) => {
    return api.getMethod("AdminRole/show?" + data, data);
  },
  addRole: (data) => {
    return api.postMethod("AdminRole/create", data);
  },
  updateRole: (data) => {
    return api.postMethod("AdminRole/update", data);
  },
  deleteRole: (data) => {
    return api.postMethod("AdminRole/delete", data);
  },
  login: (data) => {
    return api.postMethod("admin/login?", data);
  },
  getPropertiesCount: (data) => {
    return api.getMethod("Properties/get_property_count?" + data, data);
  },
  get_admin_like_property_count: (data) => {
    return api.getMethod("Properties/get_admin_like_property_count?" + data, data);
  },
  calculateNewRecordsPercentage: (data) => {
    return api.getMethod("Properties/calculateNewRecordsPercentage?" + data, data);
  },
  getUsersCount: (data) => {
    return api.getMethod("Users/get_user_count?" + data, data);
  },
  getUserActivities: (data) => {
    return api.getMethod("UserActivity/show?user_id=" + data, data);
  },
  getActiveUsers: (data) => {
    return api.getMethod("UserActivity/activityList" + data, data);
  },
  getPickedUser: (data) => {
    return api.getMethod("UserActivity/listUsers?" + data, data);
  },
  updatePickedUser: (data) => {
    return api.postMethod("UserActivity/updateUsers", data);
  },
  getAdminList: (data) => {
    return api.getMethod("UserActivity/adminList", data);
  },
  getUserFeedback: (data) => {
    return api.getMethod("UserActivity/getFeedback?user_id=" + data, data);
  },
  postUserFeedback: (data) => {
    return api.postMethod("UserActivity/addFeedback", data);
  },
  updateUserFeedback: (data) => {
    return api.postMethod("UserActivity/updateFeedback", data);
  },
  deleteUserFeedback: (data) => {
    return api.postMethod("UserActivity/deleteFeedback", data);
  },
  postAdminTicket: (data) => {
    return api.postMethod("UserActivity/ticket", data);
  },
  getSingleProperty: (data) => {
    return api.getMethod("Properties/show?" + data, data);
  },
  getState: (data) => {
    return api.getMethod("/Location/getLocation?location=state" + data, data);
  },
  getLocation: (data) => {
    return api.getMethod("Location/getLocation?" + data, data);
  },
  getUsers: (data) => {
    return api.getMethod("Users/show?" + data, data);
  },
  getUserRecentActivity: (data) => {
    return api.getMethod("users/view_history?" + data, data);
  },
  importUsers: (data) => {
    return api.postMethod("users/import_user", data);
  },
  getPayment: (data) => {
    return api.getMethod("Payments/show?" + data, data);
  },
  getForm: (data) => {
    return api.getMethod("Forms/show?" + data, data);
  },
  changeStatus: (data) => {
    return api.postMethod("Properties/update", data);
  },
  updateAdminToken: (data) => {
    return api.postMethod("Login/update_admin_token", data);
  },
  get_admin_notification_list: (data) => {
    return api.getMethod("Notify/get_admin_notification_list", data);
  },
  update_admin_notification: (data) => {
    return api.postMethod("Notify/update_admin_notification", data);
  },
  get_blogs: (data) => {
    return api.getMethod("Blogs2/show?" + data, data);
  },
  get_blogsCount: (data) => {
    return api.getMethod("Blogs2/blog_count", data);
  },
  update_blog: (data) => {
    return api.postFormMethod("Blogs2/update", data);
  },
  post_blog: (data) => {
    return api.postFormMethod("Blogs2/create", data);
  },
  delete_blog: (data) => {
    return api.postMethod("Blogs2/delete", data);
  },
  delete_form: (data) => {
    return api.postMethod("Forms/delete", data);
  },
  get_banners: (data) => {
    return api.getMethod("Banner/show", data);
  },
  update_banner: (data) => {
    return api.postFormMethod("Banner/update", data);
  },
  post_banner: (data) => {
    return api.postFormMethod("Banner/create", data);
  },
  delete_banner: (data) => {
    return api.postMethod("Banner/delete", data);
  },
  update_request: (data) => {
    return api.postMethod("Forms/update_status", data);
  },
  get_privileges: (data) => {
    return api.getMethod("admin/get_privilege?admin_id=" + data);
  },
  get_reports: (data) => {
    return api.getMethod("Reports/show?" + data, data);
  },
  block_user: (data) => {
    return api.postMethod("users/block_user", data);
  },
  get_states: (data) => {
    return api.getMethod("Location/getLocation?location=state" + data, data);
  },
  get_cities: (data) => {
    return api.getMethod("Location/getLocation?location=city&state=" + data, data);
  },
  get_localities: (data) => {
    return api.getMethod("Location/get_locality?city=" + data, data);
  },
  add_locality: (data) => {
    return api.postMethod("location/add_locality", data);
  },
  update_locality: (data) => {
    return api.postMethod("location/update_locality", data);
  },
  delete_locality: (data) => {
    return api.postMethod("location/delete_locality", data);
  },
  search_locality: (data) => {
    return api.getMethod("location/search_locality?" + data, data);
  },
  post_plan: (data) => {
    return api.postMethod("plans/create", data);
  },
  update_plan: (data) => {
    return api.postMethod("plans/update", data);
  },
  get_plans: (data) => {
    return api.getMethod("plans/show?" + data, data);
  },
  delete_plans: (data) => {
    return api.postMethod("plans/delete", data);
  },
  get_adminusers: (data) => {
    return api.getMethod("admin/show?" + data, data);
  },
};
